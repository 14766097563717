import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import {inject} from "mobx-react";
import config from "../../config/main.config";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

const PAGE_LINK = "Datenschutz";

@inject("stores")
class DataProtectionPage extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true
            }
        }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.stores.trackingStore.addVisit(PAGE_LINK);

    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'DataProtection'}
                    imageLinkId={"dataprotection-title-image-id"}
                    headerId={"dataprotection-title-header-id"}
                    subheaderId={"dataprotection-subtitle-header-id"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>DATENSCHUTZ</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2>
                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                                id={"dataprotection-title-sector-1-id"}
                                                                                namespace={'DataProtection'}/>
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <p className={"text-block"}>
                            <EditHTMLComponent textStore={this.state.textStore}
                                                                         id={"dataprotection-icon-card-1-subheader-id"}
                                                                         namespace={'DataProtection'}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default DataProtectionPage